import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import {SettingsService} from '../services/settings.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(private httpClient: HttpClient, private settingsService: SettingsService) { }

  getEventTypes(): Observable<any>{
    return this.httpClient.get(this.settingsService.getEventTypeUrl());
  }

  getEvents(deviceId: string): Observable<any>{
    return this.httpClient.get(this.settingsService.getEventsUrl()+'?deviceName='+deviceId);
  }

  getFilteredEvents(deviceName: string, eventTypes :string[], startTs: number | undefined, endTs: number | undefined, format: string): Observable<any>{
    let params = new HttpParams()
        .set("deviceName", deviceName)
        .set("eventTypes", eventTypes.join(','))
        .set("format", format)
        .set("countNumber", "100000")
    if (startTs !== undefined) 
        params = params.set("startTs", startTs.toString())
    if (endTs !== undefined) 
        params = params.set("endTs", endTs.toString())
    return this.httpClient.get(this.settingsService.getEventsUrl()+'?'+params.toString(), {responseType: 'arraybuffer'});
  }
}
