        <app-header [isError] = "false"></app-header>
        <div class="container mt30">
            <div class="flex-container" fxLayout="column" fxLayoutGap="20px">
                <div class="mt20">
                    <label class="portal-label">USER LIST</label>
                    <button mat-raised-button (click)="openModal(template,true)" class="create-button portal-font ophardt-button" [disabled] ="isDataLoading">Create User</button>
                    <button (click) ="getUserAndRoles()" mat-raised-button  class="refresh-icon portal-font" [disabled] ="isDataLoading"><mat-icon>autorenew</mat-icon>Refresh</button>
                    <mat-form-field>
                        <input matInput type="text" [(ngModel)]="filterField" [disabled] ="isDataLoading" (keyup)="doFilter($event.target.value)" placeholder="Filter">
                        <button mat-button mat-icon-button matSuffix (click)="clearFilter()" *ngIf="filterField">
                        <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div *ngIf="isDataLoading"><mat-spinner class="portal-spinner"></mat-spinner></div> 
                <div fxLayout="row wrap" fxLayoutGap="16px grid" *ngIf="!isDataLoading">
                    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let item of userObservable | async">
                          <mat-card class="mat-elevation-z9 mat-card-margin">
                            <mat-card-header>
                               <mat-card-subtitle>Username</mat-card-subtitle>
                               <mat-card-title>{{item.userName}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <span *ngFor="let role of item.roles;">
                                    <span [ngClass]="(role.roleName=='admin')?' badge-danger':''">{{role.roleName}}</span>
                                    &nbsp;
                                </span>
                            </mat-card-content>
                            <mat-card-actions>
                                <button mat-icon-button  (click)="getUserByUsername(item.userName,template,false)"><mat-icon>edit</mat-icon></button>
                                <button mat-icon-button [disabled] ="item.userName===userName" (click)="openDeleteModal(item.userName)"><mat-icon>delete</mat-icon></button> 
                            </mat-card-actions>
                          </mat-card>
                    </div>
                </div>    
                <div fxLayoutAlign="center">
                    <mat-paginator class="portal-font" [pageSizeOptions]="[8, 12, 16, 80]"></mat-paginator>
                </div>
            </div>
        </div>
        <ng-template #template>
            <div class="modal-header portal-font">
            <h2 *ngIf="isCreateMode" class="modal-title pull-left">Create New User</h2> 
            <h2 *ngIf="!isCreateMode" class="modal-title pull-left">Edit User</h2> 
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide();initializeUserForm();">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body portal-font">
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label class="required-field" for="firstName">First Name</label>
                            <input type="text" formControlName="firstName" class="form-control" placeholder="First Name" [ngClass]="{ 'is-invalid': (formFields.firstName.touched || formFields.firstName.dirty) && !formFields.firstName.valid  }" />
                            <div *ngIf="submitted && formFields.firstName.errors" class="invalid-feedback">
                                <div *ngIf="formFields.firstName.errors.required">First Name is required</div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="required-field" for="lastName">Last Name</label>
                            <input type="text" formControlName="lastName" class="form-control" placeholder="Last Name" [ngClass]="{ 'is-invalid':  (formFields.lastName.touched || formFields.lastName.dirty) && !formFields.lastName.valid  }" />
                            <div *ngIf="submitted && formFields.lastName.errors" class="invalid-feedback">
                                <div *ngIf="formFields.lastName.errors.required">Last Name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label class="required-field" for="userName">Username</label>
                            <input type="text" [readonly]="!isCreateMode" formControlName="userName" placeholder="Username" class="form-control" [ngClass]="{ 'is-invalid': (formFields.userName.touched || formFields.userName.dirty) && !formFields.userName.valid }" />
                            <div *ngIf="submitted && formFields.userName.errors" class="invalid-feedback">
                                <div *ngIf="formFields.userName.errors.required">Username is required</div>
                                <div *ngIf="formFields.userName.errors.unavailable">Username is taken</div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="required-field" for="customerName">Customer Name</label>
                            <select formControlName="customerName" class="form-control" required [ngClass]="{ 'is-invalid': (formFields.customerName.touched || formFields.customerName.dirty) && !formFields.customerName.valid }">
                                <option value="" disabled>Choose Customer</option>
                                <option *ngFor="let customer of customerList" [ngValue] = "customer">{{customer}}</option>
                            </select>
                            <div *ngIf="submitted && formFields.customerName.errors" class="invalid-feedback">
                                <div *ngIf="formFields.customerName.errors.required">Customer Name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <div class="form-group password-label">
                                <label class="required-field"  placeholder="Password" for="password">Password</label>
                            </div>
                            <div class="form-row">
                                <div class="form-group input-group col-12">
                                    <input [type]="showPassword ? 'text' : 'password'" minlength="10" formControlName="password" size ="40" class="form-control" [ngClass]="{ 'is-invalid': (formFields.password.touched || formFields.password.dirty) && !formFields.password.valid }" 
                                    (ngModelChange)=resetConfirmPassword()/>
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            <mat-icon matSuffix (click)="password()">{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </span>
                                    </div>
                                    <div *ngIf="submitted && formFields.password.errors" class="invalid-feedback">
                                        <div *ngIf="formFields.password.errors.minlength">Password must be at least 10 characters</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 padding-top">
                            <div class="form-group form-check">
                                <input type="checkbox" formControlName="enabled" id="enabled" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && formFields.enabled.errors }" />
                                <label for="enabled" class="form-check-label">Enabled</label>
                            </div>
                            <div class="form-group form-check">
                                <input type="checkbox" formControlName="admin" id="admin" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && formFields.admin.errors }" />
                                <label for="admin" class="form-check-label">Admin</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <div class="form-group password-label">
                                <label class="required-field" placeholder="Confirm Password" for="confirmPassword">Confirm Password</label>
                            </div>
                            <div class="form-row">
                                <div class="form-group input-group col-12">
                                    <input [type]="showConfirmPassword ? 'text' : 'password'" minlength="10" formControlName="confirmPassword" size ="40" class="form-control" [ngClass]="{ 'is-invalid': (formFields.confirmPassword.touched || formFields.confirmPassword.dirty) && !formFields.confirmPassword.valid }" />
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            <mat-icon matSuffix (click)="confirmPassword()">{{showConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </span>
                                    </div>
                                    <div *ngIf="submitted && formFields.confirmPassword.errors" class="invalid-feedback">
                                        <div *ngIf="formFields.confirmPassword.errors.minlength">Password must be at least 10 characters</div>
                                        <div *ngIf="formFields.confirmPassword.errors.mustMatch">Passwords must match</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-6 padding-top modal-button">
                            <button [disabled]="loading" class="btn ophardt-button button-text">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Submit
                            </button>
                            <button type="button" class="btn cancel-btn" (click) ="modalRef.hide();initializeUserForm()">
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>