import { Component, OnInit, ViewChild } from '@angular/core';

import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import {EventsService} from '../services/events.service';
import { ActivatedRoute } from '@angular/router';
import {MatSort} from '@angular/material/sort';
import {Event} from '../models/event.model';
import {AuthService} from '../services/auth.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';
import { saveAs } from 'file-saver/dist/FileSaver';

class EventType {
  name: string;
  selected: boolean;
  disabled: boolean;
  eventTypes?: EventType[];
}

@Component({
  selector: 'app-eventspage',
  templateUrl: './eventspage.component.html',
  styleUrls: ['./eventspage.component.sass']
})
export class EventspageComponent implements OnInit {

  deviceId : string;
  selectableEventTypes: string[];
  isDataLoading :boolean = false;
  isFiltering : boolean = false;
  displayedColumns: string[] = ['eventType', 'occuredAt', 'value'];
  filterField: string;
  eventTypeList: Array<EventType> =[];
  eventType: EventType = new EventType();
  filterEventTypes: string[] =[];
  eventTypesChecked: boolean = false;
  eventTypesIndeterminate: boolean = false;
  checkedCount:number = 0;
  startTime: moment.Moment = null;
  endTime: moment.Moment = null;
  format: string | null;
  public dataSourceForEvents = new MatTableDataSource<Event>();
  public disabled = false;
  public showSpinners = true;
  public showSeconds = true;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment = moment().add(1, 'days');
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'warn';


  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  @ViewChild(MatSort, {static: false}) set content(sort: MatSort) {
    this.dataSourceForEvents.sort = sort;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private eventsService: EventsService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.isFiltering = false;
    this.activatedRoute.paramMap.subscribe(params => { 
      this.deviceId = params.get('id'); 
  });
    this.eventsService.getEventTypes().subscribe((data)=>
    {
      this.selectableEventTypes = Object.assign([], data);
      this.selectableEventTypes.forEach(item =>{
        let eventType = new EventType();
        eventType.name = item;
        eventType.disabled =false;
        eventType.selected = false;
        this.eventTypeList.push(eventType);
      });
      this.eventType.eventTypes = this.eventTypeList;
    })
    this.getEvents();
  }

  ngAfterViewInit() {
    this.dataSourceForEvents.paginator = this.paginator;
  }

  masterCheckBoxChange() {
    this.checkedCount = 0;
    this.filterEventTypes =[];
    for (let value of Object.values(this.eventType.eventTypes)) {
      value.selected = this.eventTypesChecked;
      
      if(this.eventTypesChecked) {
        this.checkedCount++;
        this.filterEventTypes.push(value.name);
      }
    }
    if(this.isFiltering){
      this.getFilteredEvents(false);
    }
     
  }

  CheckboxListChange(){
    this.filterEventTypes =[];
    this.checkedCount = 0;
    for (let value of Object.values(this.eventType.eventTypes)) {
      if(value.selected){
      this.filterEventTypes.push(value.name);
      this.checkedCount++;
      }
    }

    if(this.checkedCount>0 && this.checkedCount<this.eventType.eventTypes.length){
      this.eventTypesIndeterminate = true;
    }else if(this.checkedCount == this.eventType.eventTypes.length){
      this.eventTypesIndeterminate = false;
      this.eventTypesChecked = true;
    }else{
      this.eventTypesIndeterminate = false;
      this.eventTypesChecked = false;
    }
    this.getFilteredEvents(false);
  }

  getEvents(){
    this.isDataLoading = true;
    this.eventsService.getEvents(this.deviceId)
    .pipe(
      finalize(() => this.isDataLoading = false)
   )
    .subscribe(
      data=>{    
        this.eventTypesChecked = true;
        this.masterCheckBoxChange();
        this.isFiltering = true;
        this.dataSourceForEvents.data = data as Event[];
        this.dataSourceForEvents.paginator = this.paginator;
      }
    )
  }

  validateField(isButtonClick: boolean) : boolean {
    
  
    if ((this.isMomentNull(this.startTime)  && !this.isMomentNull(this.endTime)) 
    || (!this.isMomentNull(this.startTime) && this.isMomentNull(this.endTime))) {
        this.toastr.error("Please select both Start Time and End Time");
        return false;
    }
    if ((!this.isMomentNull(this.startTime) && !this.isMomentNull(this.endTime)) 
    && (this.startTime.valueOf() > this.endTime.valueOf()) ){
        this.toastr.error("Start time must be before End Time");
        return false;
      }
    if(this.checkedCount == 0 && this.filterEventTypes.length == 0){
      if(isButtonClick) this.toastr.error("Please make sure atleast one event type is selected!");
        this.dataSourceForEvents.data = [];
        this.isDataLoading = false;
        return false;
      }
    return true;
  }

  getFilteredEvents(isButtonClick : boolean){
    this.isDataLoading = true;
    if(!this.validateField(isButtonClick)){
      this.isDataLoading = false;
    } else{
      this.eventsService.getFilteredEvents(this.deviceId,this.filterEventTypes, this.getTimeStamp(this.startTime), this.getTimeStamp(this.endTime), 'JSON')    .pipe(
        finalize(() => this.isDataLoading = false)
     ).subscribe(
        (data) => {
        data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(data)))
        this.dataSourceForEvents.data = data as Event[];
        }
      )
    }
  }

  exportEvents(isButtonClick : boolean){
    if(!this.validateField(isButtonClick)){
      this.isDataLoading = false;
    } else {
    this.eventsService.getFilteredEvents(this.deviceId,this.filterEventTypes, this.getTimeStamp(this.startTime), this.getTimeStamp(this.endTime), 'CSV')    .pipe(
        finalize(() => this.isDataLoading = false)
     ).subscribe(
      (data) => {
        var file = new File([data], "telemetries.csv", { type: 'text/plain;charset=utf-8' });
        saveAs(file);
      } );
    }
  }

 public doFilter = (value: string) => {
  this.dataSourceForEvents.filter = value.trim().toLocaleLowerCase();
}

clearFilter(){
  this.dataSourceForEvents.filter = '';
  this.filterField = '';
}

isMomentNull(ts: moment.Moment) : boolean{
  return ts === null;
}

getTimeStamp(ts : moment.Moment) {
  return this.isMomentNull(ts) ? undefined : ts.valueOf();
}

}
