        <app-header [isError] = "false"></app-header>
        <div class="container mt30">
            <div class="flex-container" fxLayout="column" fxLayoutGap="20px">
                <div class="mt20">
                    <label class="portal-label">CUSTOMER DETAILS :&nbsp;</label><span class="portal-label">{{customerName}}</span>
                </div>
                <div *ngIf="isLoading"><mat-spinner class="portal-spinner"></mat-spinner></div> 
                <div *ngIf="!isLoading">
                    <mat-table [dataSource]="dataSourceForCustomer" class="mat-elevation-z8">
                        <ng-container matColumnDef="totalDevices">
                            <mat-header-cell class="portal-font" *matHeaderCellDef >Number of Devices</mat-header-cell>
                            <mat-cell  class="portal-font" *matCellDef="let item">{{item.totalDevices}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="totalUsers">
                            <mat-header-cell *matHeaderCellDef class="portal-font">Number of Users</mat-header-cell>
                            <mat-cell class="portal-font" *matCellDef="let item">{{item.totalUsers}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="entityGroupId">
                            <mat-header-cell class="portal-font" *matHeaderCellDef>ThingsBoard GroupId</mat-header-cell>
                            <mat-cell  class="portal-font" *matCellDef="let item">{{item.entityGroupId}}</mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumnsOfCustomer"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsOfCustomer"></mat-row>
                    </mat-table>
                </div>

                <div class="mt20">
                    <label class="portal-label">DEVICE DETAILS</label>
                    <button mat-raised-button (click)="openDeviceModal(template)" class="create-button portal-font ophardt-button" [disabled] ="isLoading">Add Devices</button>
                    <button mat-raised-button (click)="openModal(upload)" class="create-button portal-font ophardt-button" [disabled] ="isLoading">Upload File</button>
                </div>
                <div *ngIf="isDataLoading"><mat-spinner class="portal-spinner"></mat-spinner></div>  
                <div *ngIf="!isDataLoading">
                    <mat-table [dataSource]="dataSourceForDevices" class="mat-elevation-z8">
                        <ng-container matColumnDef="name">
                            <mat-header-cell class="portal-font" *matHeaderCellDef >DEVICE NAME</mat-header-cell>
                            <mat-cell class="portal-font" *matCellDef="let item">{{item.name}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <mat-header-cell class="portal-font" *matHeaderCellDef >DEVICE TYPE</mat-header-cell>
                            <mat-cell  class="portal-font" *matCellDef="let item">{{item.type}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Action">
                            <mat-header-cell *matHeaderCellDef class="portal-font"> ACTION </mat-header-cell>
                            <mat-cell class="portal-font" *matCellDef="let item;let i= index">
                                <button mat-icon-button (click)=openDeleteModal(item.id.id,item.name)><mat-icon>delete</mat-icon></button>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumnsOfDevices"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsOfDevices"></mat-row>
                    </mat-table>
                </div>
                <div fxLayoutAlign="center">
                    <mat-paginator class="portal-font" [pageSizeOptions]="[10, 15, 25, 100]"></mat-paginator>
                </div>
            </div>
        </div>

        <ng-template #template>
            <div class="modal-header portal-font">
            <h2 class="modal-title">Add New Devices</h2> 
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide();resetDeviceModal()">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div *ngIf="isTableLoading"><mat-spinner class="ml24 portal-spinner"></mat-spinner></div> 
            <div *ngIf="!isTableLoading" class="modal-body portal-font">
                <mat-form-field>
                    <input matInput type="text" [(ngModel)]="filterField" (keyup)="doDeviceFilter($event.target.value)" placeholder="Filter">
                    <button mat-button mat-icon-button matSuffix (click)="clearFilter()" *ngIf="filterField">
                    <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-table [dataSource]="dataSourceForNewDevices" class="mat-elevation-z8">
                    <ng-container matColumnDef="select">
                        <mat-header-cell *matHeaderCellDef class="portal-font">
                          <mat-checkbox (change)="$event ? masterCheckboxToggle() : null"
                                        [checked]="selection.hasValue() && isAllDevicesSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllDevicesSelected()">
                          </mat-checkbox> <span class="checkboxSpan">{{checkedCount}} SELECTED</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                          <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null; updateSelectedDeviceList(row.id.id,selection.isSelected(row))"
                                        [checked]="selection.isSelected(row)">
                          </mat-checkbox>
                        </mat-cell>
                      </ng-container>
                    <ng-container matColumnDef="name">
                        <mat-header-cell class="portal-font" *matHeaderCellDef >DEVICE NAME</mat-header-cell>
                        <mat-cell class="portal-font" *matCellDef="let item">{{item.name}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="type">
                        <mat-header-cell class="portal-font" *matHeaderCellDef >DEVICE TYPE</mat-header-cell>
                        <mat-cell  class="portal-font" *matCellDef="let item">{{item.type}}</mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumnsOfNewDevices; sticky:true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsOfNewDevices" (click)="selection.toggle(row)"></mat-row>
                </mat-table>
            </div>
            <div class="modal-footer portal-font">
                <button [disabled]="isTableLoading" class="btn ophardt-button button-text" (click)="addDevicesToCustomer()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Add
                </button>
                <button class="btn cancel-btn" (click) ="modalRef.hide();resetDeviceModal()">
                    Cancel
                </button>
            </div>
        </ng-template>


        <ng-template #upload>
            <button type="button" class="close" aria-label="Close" (click)="modalRef.hide(); deleteFile()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="drop-zone">
                <input type="file" accept=".txt" (change)="onFileSelected($event)" #fileDropRef id="fileDropRef" />
                <img src="/assets/ic-upload-file.svg" class="download-img" />
                <h3>Drag & Drop File Here</h3>
                <h3>or</h3>
                <button class="btn ophardt-button button-text">Choose File</button>
                <a href='assets/sample.txt' download="sample.txt">
                    Download a sample template here
                </a>
            </div>
            <div class="file-progress" *ngIf="isFilePresent">
                <div>
                    <mat-icon>file_present</mat-icon>
                    <h4>
                        {{ file?.name }}
                    </h4>
                </div>
                <button mat-icon-button (click)="uploadFile()">
                    <mat-icon class="upload-btn">import_export</mat-icon>
                </button>
                <button mat-icon-button [disabled]="fileInProgress" (click)="deleteFile()">
                    <mat-icon>delete_forever</mat-icon>
                </button>
                <mat-progress-bar class="progress-bar" mode="buffer" *ngIf="fileInProgress">
                </mat-progress-bar>
            </div>
        </ng-template>