import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginpageComponent } from './loginpage/loginpage.component';
import {LogoutpageComponent} from './logoutpage/logoutpage.component';
import {EventspageComponent} from './eventspage/eventspage.component';
import {ErrorpageComponent} from './errorpage/errorpage.component';
import {UserlistpageComponent} from './userlistpage/userlistpage.component';
import {CustomerlistComponent} from './customerlist/customerlist.component';
import {CustomerdetailsComponent} from './customerdetails/customerdetails.component';
import {UsertokensComponent} from './usertokens/usertokens.component';

const routes: Routes = [
  { path: '',pathMatch: 'full',redirectTo: 'home'},
  { path: 'home', component: HomepageComponent},
  { path: 'login', component: LoginpageComponent},
  { path: 'logout', component: LogoutpageComponent},
  { path: 'events/:id', component: EventspageComponent},
  { path: 'error', component: ErrorpageComponent},
  { path: 'userList', component: UserlistpageComponent},
  { path: 'customerList', component: CustomerlistComponent},
  { path: 'customerDetails/:id', component: CustomerdetailsComponent},
  { path: 'userTokens', component: UsertokensComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
