import { Component, Input } from '@angular/core';
import { ProfileService } from '../services/profile.service';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
})
export class AppHeaderComponent {
  @Input() isError: boolean;
  userName: string;
  isAdmin: boolean = false;
  navbarCollapsed: boolean = true;

  constructor(
    private profileService: ProfileService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.userName = this.profileService.getProfileName();
    this.isAdmin = this.profileService.isAdmin();
  }

  logout() {
    this.authService.logout();
  }
}
