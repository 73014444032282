        <app-header [isError] = "false"></app-header>
        <div class="homepage-margin">
            <div fxLayout="column" fxLayoutGap="20px">
                <div class="mt20">
                    <label class="portal-label">DEVICE LIST</label>
                    <button [disabled] ="isLoading" (click) ="getDevices()" mat-raised-button  class="refresh-icon portal-font"><mat-icon>autorenew</mat-icon>Refresh</button>
                    <mat-form-field>
                        <input matInput type="text" [(ngModel)]="filterField" [disabled] ="isLoading" (keyup)="doFilter($event.target.value)" placeholder="Filter">
                        <button mat-button mat-icon-button matSuffix (click)="clearFilter()" *ngIf="filterField">
                        <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div *ngIf="isLoading"><mat-spinner class="ml45 portal-spinner"></mat-spinner></div>
                <div *ngIf="!isLoading && showTable">
                    <mat-table [dataSource]="dataSourceForDevices" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8">
                        <ng-container matColumnDef="name">
                            <mat-header-cell class="portal-font" *matHeaderCellDef mat-sort-header >NAME</mat-header-cell>
                            <mat-cell class="portal-font" *matCellDef="let item">
                                <a [routerLink]="['/events', item.name]"> {{item.name}} </a>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <mat-header-cell class="portal-font" *matHeaderCellDef mat-sort-header >TYPE</mat-header-cell>
                            <mat-cell class="portal-font" *matCellDef="let item">{{item.type}}</mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                    </mat-table>
                </div>
                <div fxLayoutAlign="center">
                    <mat-paginator class="portal-font" [pageSizeOptions]="[10, 15, 25, 100]"></mat-paginator>
                </div>
            </div>
        </div>

