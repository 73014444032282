import { Component, OnInit } from '@angular/core';
import { ProfileService} from './services/profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit{
  title = 'portal-ui';
  code:string;
  isAdmin : boolean;

  constructor(private profileService:ProfileService) {
  }

  ngOnInit() {
    if(localStorage.getItem('isRefreshTimerOn') !== 'true'){
      localStorage.setItem('isRefreshTimerOn', 'false');
      localStorage.setItem('portalToken', null);
      localStorage.setItem('refreshToken',null);
    } else{
      this.isAdmin=this.profileService.isAdmin();
    }

    this.profileService.tokenSubscriber$.subscribe(data => {
      this.isAdmin = data;
    });

  }

}