        <app-header [isError] = "false"></app-header>

        <div class="container mt30">
            <div class="flex-container" fxLayout="column" fxLayoutGap="20px">
                <div class="mt20">
                    <label class="portal-label">CUSTOMER LIST</label>
                    <button mat-raised-button (click)="openModal(template,true)" class="create-button portal-font ophardt-button" [disabled] ="isLoading">Create Customer</button>
                    <button (click) ="getCustomers()" mat-raised-button  class="refresh-icon portal-font" [disabled] ="isLoading"><mat-icon>autorenew</mat-icon>Refresh</button>
                    <mat-form-field>
                        <input matInput type="text" [(ngModel)]="filterField" [disabled] ="isLoading" (keyup)="doFilter($event.target.value)" placeholder="Filter">
                        <button mat-button mat-icon-button matSuffix (click)="clearFilter()" *ngIf="filterField">
                        <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div *ngIf="isLoading"><mat-spinner class="portal-spinner"></mat-spinner></div> 
                <div *ngIf="!isLoading">
                    <mat-table [dataSource]="dataSourceForCustomers" matSort class="mat-elevation-z8">
                        <ng-container matColumnDef="name">
                            <mat-header-cell class="portal-font" *matHeaderCellDef mat-sort-header>CUSTOMER NAME</mat-header-cell>
                            <mat-cell class="portal-font" *matCellDef="let item">{{item.name}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Action">
                            <mat-header-cell *matHeaderCellDef class="portal-font"> ACTIONS </mat-header-cell>
                            <mat-cell class="portal-font" *matCellDef="let item;let i= index">
                                <button mat-icon-button  (click)="getCustomerById(item.id,i,template,false)">
                                    <span *ngIf="isSpinnerOn && (i==selectedIndex)" class="spinner-border spinner-border-sm mr-1"></span>
                                    <mat-icon *ngIf="!isSpinnerOn || (i!=selectedIndex)">edit</mat-icon>
                                </button> |
                                <button mat-icon-button (click)=openDeleteModal(item.id,item.name)><mat-icon>delete</mat-icon></button> |
                                <button mat-icon-button><mat-icon [routerLink]="['/customerDetails', item.id]">description</mat-icon></button>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                    </mat-table>
                </div>
                <div fxLayoutAlign="center">
                    <mat-paginator class="portal-font" [pageSizeOptions]="[10, 15, 25, 100]"></mat-paginator>
                </div>
            </div>
        </div>
        <ng-template #template>
            <div class="modal-header portal-font">
            <h2 *ngIf="isCreateMode" class="modal-title pull-left">Create New Customer</h2> 
            <h2 *ngIf="!isCreateMode" class="modal-title pull-left">Edit Customer</h2> 
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide();resetCustomerForm();">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body portal-font">
                <form [formGroup]="customerForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="name">Customer Name</label>
                        <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && formFields.name.errors }" />
                        <div *ngIf="submitted && formFields.name.errors" class="invalid-feedback">
                            <div *ngIf="formFields.name.errors.required">Customer Name is required</div>
                        </div>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" formControlName="ohmsCustomer" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && formFields.ohmsCustomer.errors }" />
                        <label for="ohmsCustomer" class="form-check-label">OHMS Customer</label>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" formControlName="owmsCustomer" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && formFields.owmsCustomer.errors }" />
                        <label for="owmsCustomer" class="form-check-label">OWMS Customer</label>
                    </div>
                    <div class="form-group">
                        <button [disabled]="loading" class="btn ophardt-button button-text">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Submit
                        </button>
                        <button class="btn cancel-btn" (click) ="modalRef.hide();resetCustomerForm()">
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </ng-template>
