import { Component, OnInit } from '@angular/core';

import {SettingsService} from '../services/settings.service';
import { ProfileService} from '../services/profile.service';

@Component({
  selector: 'app-logoutpage',
  templateUrl: './logoutpage.component.html',
  styleUrls: ['./logoutpage.component.sass']
})
export class LogoutpageComponent implements OnInit {

  constructor(private settingsService:SettingsService,private profileService:ProfileService) { }

  ngOnInit(): void {
    this.profileService.setUserLoggedIn(false);
    localStorage.clear();
    window.location.href = this.settingsService.getLoginUrl();
  }

}
