import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable,Injector } from '@angular/core';
import {SettingsService} from '../services/settings.service';

import jwt_decode from "jwt-decode";
import { map,catchError} from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
  })
export class AuthService {

    private refreshTokenTimeout;
    isTimerOn : boolean = false;
    private get httpClient() { return this._injector.get(HttpClient); }
    private get settingsService() { return this._injector.get(SettingsService); }
    private get toastr() { return this._injector.get(ToastrService); }
    private get router() { return this._injector.get(Router); }

    constructor(private _injector: Injector) {

    }
    getAuthToken() {
        return localStorage.getItem('portalToken');
    }

    getRefreshToken(){
        return this.httpClient.get(this.settingsService.getRefreshUrl()+"?refresh-token="+localStorage.getItem('refreshToken')).pipe(
            map((res) =>{
            localStorage.setItem('portalToken',res['access_token']);
            localStorage.setItem('refreshToken', res['refresh_token']);
            this.startRefreshTokenTimer();
            },
            catchError(error =>{return throwError(error.status);}))
        );
    }

    logout() {
        this.stopRefreshTokenTimer();
        localStorage.clear();
        window.location.href = this.settingsService.getLogoutUrl();
    }

     startRefreshTokenTimer() {
        let token = localStorage.getItem('portalToken');
        let decodedToken = jwt_decode(token);
        let expires = new Date(decodedToken['exp'] * 1000);
        let timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.getRefreshToken().subscribe((result)=>{},(error)=>{if(error.status == 401)this.logout()}), timeout);
    }

     stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    redirectToLoginPage() {
        this.toastr.error("Token Expired !!!");
          setTimeout (() => {
            this.router.navigate(['/logout']);
         }, 2000);
    }
}
