import { Injectable } from '@angular/core';

import {HttpInterceptor,HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

import { AuthService } from '../services/auth.service';
import { ProfileService} from '../services/profile.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{

  constructor(private authenticationService: AuthService, private profileService: ProfileService,private toastr: ToastrService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url.includes(environment.basePortalHref)){  
        return this.createTokenizedRequest(req,next,'portalToken', 'Bearer ');  
    } else if(req.url.includes(environment.baseAPIHref)){
        return this.createTokenizedRequest(req,next,'apiToken', '');
    }
  }


createTokenizedRequest(request: HttpRequest<any>,next: HttpHandler,requestToken: string,tokenPrefix: string) {
  let token = localStorage.getItem(requestToken);
  if(token){
    let tokenizedReq = request.clone({
      setHeaders :{
        'Authorization' : `${tokenPrefix}${token}`,
        'Content-Type': 'application/json'
      }
    })
    return this.processRequest(tokenizedReq,next);
  } else {
      return next.handle(request);
    }
}

processRequest(tokenizedReq: HttpRequest<any>, next: HttpHandler) {
  return next.handle(tokenizedReq).pipe(catchError(err => {
    if ([401, 403].includes(err.status) && this.profileService.getUserLoggedIn()) {
        this.toastr.error('Error: Session Expired!'); 
        this.profileService.setUserLoggedIn(false);
        this.authenticationService.logout();
    }
    if([500].includes(err.status)) {
      this.toastr.error("Error: The server was unable to complete your request. Please contact administrator!");
    } else {
      (err.error.errorDescription)? this.toastr.error(err.error.errorDescription ): 
                              this.toastr.error('Error: Please try again later!'); 
    }
    return throwError(err);
  }));
}
}
