import { AuthService } from '../app/services/auth.service';
import {SettingsService} from '../app/services/settings.service';

export function appInitializer(authenticationService: AuthService, settingsService:SettingsService) {
    if(window.location.search === "" && localStorage.length === 0) {
        window.location.href = settingsService.getLoginUrl();
        return;
      }
        return () => new Promise(resolve => {
            authenticationService.getRefreshToken()
                .subscribe()
                .add(resolve);
        });
}