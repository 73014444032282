import { Component, OnInit,AfterViewInit, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { User } from '../models/user.model';
import { CustomerService } from '../services/customers.service';
import { AuthService } from '../services/auth.service';
import { finalize } from 'rxjs/operators';
import { PasswordMatch } from '../helpers/password-match.validator';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {deleteModal} from '../helpers/delete-modal';
import { MatCardModule} from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { Observable } from 'rxjs/internal/Observable';
import { ProfileService } from '../services/profile.service';

@Component({
  selector: 'app-userlistpage',
  templateUrl: './userlistpage.component.html',
  styleUrls: ['./userlistpage.component.sass'],
})
export class UserlistpageComponent implements OnInit {
  displayedColumns: string[] = ['userName', 'roles', 'action'];
  public dataSourceForUsers = new MatTableDataSource<any>();
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  modalRef: BsModalRef;
  isCreateMode: boolean = true;
  user: User = new User();
  customerList: string[] = [];
  isDataLoading: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  filterField: string;
  userNameList: string[] = [];
  userObservable: Observable<any>;
  userName: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) set content(sort: MatSort) {
    this.dataSourceForUsers.sort = sort;
  }
  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    public modalService: BsModalService,
    private toastr: ToastrService,
    private customerService: CustomerService,
    private authService:AuthService,
    public dialog: MatDialog,
    private cdRef : ChangeDetectorRef,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.userName = this.profileService.getProfileName();
    this.userObservable = this.dataSourceForUsers.connect();
    this.customerService.getCustomers().subscribe(
      (data) => {data.forEach(customer => {this.customerList.push(customer.name);});},
      (error) => {
        this.toastr.error(error.status+': Unable to get customer list!'); 
      }
    );
    this.getUserAndRoles();
    this.createUserForm();
    this.dataSourceForUsers.paginator = this.paginator;
    this.dataSourceForUsers.filterPredicate = function(data, filter: string) : boolean {
      let roleFilter:boolean = false;
      data.roles.forEach((value)=> {
          value.roleName.toLowerCase().includes(filter) ? roleFilter=true : roleFilter=false;
        })
        return data.userName.toLowerCase().includes(filter) || roleFilter;
    };
  }

  ngAfterViewInit() {
    this.dataSourceForUsers.paginator = this.paginator;
  }

createUserForm() {
  this.registerForm = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    userName: ['', Validators.required],
    password: ['',this.isCreateMode ? Validators.required : Validators.nullValidator],
    customerName: ['', Validators.required],
    enabled: [false],
    admin: [false], 
    confirmPassword: ['']
  }, {
    validators: [PasswordMatch('password', 'confirmPassword'), this.UniqueUsername('userName')]
  }
  );
}

UniqueUsername(userName: string){
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[userName];
    if (control.valid) {
      var username = control.value.toLowerCase();
      const isUniqueUsername = this.userNameList.find(userName =>
        userName.toLowerCase() === username || null);
      if (this.isCreateMode && isUniqueUsername !== undefined) {
        control.setErrors({unavailable: true});
      }
    }
  } 
}

password() {
  this.showPassword = !this.showPassword;
}

  // TODO : replace Math.random with a better randomize
  randomPassword(length) {
    var chars =
      'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890';
    var pass = '';
    for (var x = 0; x < length; x++) {
      var i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    return pass;
  }

  generate() {
    this.formFields.password.setValue(this.randomPassword(10));
    this.formFields.confirmPassword.setValue(this.formFields.password.value);
  }

  getUserAndRoles() {
    this.isDataLoading = true;
    this.userService.getUsersAndRoles()
    .pipe(
      finalize(() => this.isDataLoading = false)
   )
    .subscribe((data) => {
      this.dataSourceForUsers.data = data;
      data.forEach(user => {this.userNameList.push(user.userName);})
    }
    );
  }

  get formFields() {
    return this.registerForm.controls;
  }

  initializeUserForm() {
    this.registerForm.reset();
    this.submitted = false;
    this.showConfirmPassword = false;
    this.showPassword = false;
  }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid || !this.registerForm.touched) {
      this.toastr.error('Error: Form is Invalid/Unchanged!'); 
      return;
    }
    this.loading = true;
    if (this.isCreateMode) {
      this.createUser();
    } else {
        this.updateUser();
    }
  }

 isPasswordEntered()  : boolean {
    if(this.isCreateMode && (this.formFields.password.value == null ||this.formFields.password.value =="" || this.formFields.password.value == undefined ) ){
      return false;
    }else{ return true ;}
  }

  openModal(template: TemplateRef<any>, createMode : boolean) {
    this.isCreateMode = createMode;
    if(this.isCreateMode) {
      this.createUserForm();
      this.generate();
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static',
    keyboard: false, class: 'modal-lg modal-template modal-checkbox'});
  }

  getUserByUsername(userName: string, template: TemplateRef<any>,createMode : boolean) {
    this.userService.getUserByUserName(userName) 
    .pipe(first())
    .subscribe(response => {
      this.isCreateMode = createMode;
      this.createUserForm();
      this.user.firstName = response.firstName ? response.firstName : '-';
      this.user.lastName = response.lastName ? response.lastName : '-';
      this.customerList.forEach(customer => {
        if(customer === response.customerName ){
         this.user.customerName = customer;
         this.registerForm.get('customerName').patchValue(customer);
        } 
      });
      this.user.userName = response.userName ? response.userName : '-';
      this.user.enabled = response.enabled ? response.enabled : false;
      this.user.admin = response.admin ? response.admin : false;
      if(response.admin && response.userName === this.userName) {
        this.registerForm.get('admin').disable();
      }
      
      this.registerForm.patchValue(this.user);
      this.openModal(template,createMode);
    }
      );
      

  }

  createUser(){
    this.userService.createUser(this.registerForm.value)
    .pipe(
      finalize(() => {
        this.loading = false;
      })
    )
    .subscribe(
      (data) => {
        this.toastr.success('Creation Successful!');
        this.initializeUserForm();
        this.modalRef.hide();
        this.getUserAndRoles();
      }
    );
  }

  updateUser () {
    this.userService.updateUser(this.registerForm.value)
    .pipe(
      finalize(() => {
        this.loading = false;
      })
    )
    .subscribe(
      (data) => {
        this.toastr.success('Updation Successful!');
        this.initializeUserForm();
        this.modalRef.hide();
        this.getUserAndRoles();
      }
    );
  }

  confirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  doFilter(value: string) {
    this.dataSourceForUsers.filter = value.trim().toLocaleLowerCase();
  }

  clearFilter(){
    this.dataSourceForUsers.filter = '';
    this.filterField = '';
 }

 openDeleteModal(userName: string): void {
  const dialogRef = this.dialog.open(deleteModal, {
    width: '450px',
    data: {name: userName}
  });

  dialogRef.afterClosed().subscribe(result => {
    if(result) this.deleteUser(userName);
  });
}

deleteUser (userName: string) {
  this.userService.deleteUser(userName)
  .subscribe(
    (data) => {
      this.toastr.success('Deletion Successful!');
      this.getUserAndRoles();
    }
  );
}

resetConfirmPassword() {
  this.formFields.confirmPassword.setValue('');
}

ngAfterContentChecked() {
  this.cdRef.detectChanges();
}

ngOnDestroy(): void {
  if (this.dataSourceForUsers) { this.dataSourceForUsers.disconnect(); }
}
  
}
