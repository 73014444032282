<app-header [isError]="false"></app-header>
<div fxLayout="row" fxLayoutGap="50px" class="mt30 event-controls">
    <div fxFlex="20" class=" checkboxMargin">
        <button mat-raised-button (click)="exportEvents(true)" *ngIf="!isDataLoading"
            class="portal-font ophardt-button export-button">
            Export
            <mat-icon class="portal-header-icon">file_download</mat-icon>
        </button>
        <mat-list class="event-type-list" *ngIf="isFiltering">
            <mat-list-item>
                <mat-checkbox [(ngModel)]="eventTypesChecked" [(indeterminate)]="eventTypesIndeterminate"
                    (change)="masterCheckBoxChange()">
                    <b>All Event Types</b>
                </mat-checkbox>
            </mat-list-item>
            <mat-list-item class="event-checkbox-label" *ngFor="let item of eventType.eventTypes">
                <mat-checkbox class="event-checkbox-label" [(ngModel)]="item.selected" [disabled]="item.disabled"
                    (change)="CheckboxListChange()">{{ item.name }}</mat-checkbox>
            </mat-list-item>
        </mat-list>
    </div>
    <div fxFlex="70">
        <button mat-raised-button (click)="getFilteredEvents(true)" *ngIf="!isDataLoading"
            class="portal-font ophardt-button">Get Events</button>
        <mat-form-field class="form-horizontal date-time-picker" *ngIf="!isDataLoading">
            <mat-label>Start Time</mat-label>
            <input matInput [ngxMatDatetimePicker]="starttimepicker" [(ngModel)]="startTime" [min]="minDate"
                [max]="maxDate" [disabled]="disabled">
            <mat-datepicker-toggle matSuffix [for]="starttimepicker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #starttimepicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
                [color]="color" [enableMeridian]="enableMeridian">
            </ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field class="date-time-picker" *ngIf="!isDataLoading">
            <mat-label>End Time</mat-label>
            <input matInput [ngxMatDatetimePicker]="endtimepicker" [(ngModel)]="endTime" [min]="minDate" [max]="maxDate"
                [disabled]="disabled">
            <mat-datepicker-toggle matSuffix [for]="endtimepicker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #endtimepicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
                [color]="color" [enableMeridian]="enableMeridian">
            </ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field class="filter-icon" *ngIf="!isDataLoading">
            <input matInput type="text" [(ngModel)]="filterField" (keyup)="doFilter($event.target.value)"
                placeholder="Filter">
            <button mat-button mat-icon-button matSuffix (click)="clearFilter()" *ngIf="filterField">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <div *ngIf="isDataLoading">
            <mat-spinner class="portal-spinner"></mat-spinner>
        </div>
        <div *ngIf="!isDataLoading">
            <mat-table [dataSource]="dataSourceForEvents" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="eventType">
                    <mat-header-cell class="portal-font" *matHeaderCellDef mat-sort-header>EVENT TYPE</mat-header-cell>
                    <mat-cell class="portal-font" *matCellDef="let item">
                        {{item.eventType}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="occuredAt">
                    <mat-header-cell class="portal-font" *matHeaderCellDef mat-sort-header>TIME</mat-header-cell>
                    <mat-cell class="portal-font" *matCellDef="let item">{{item.occuredAt}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="value">
                    <mat-header-cell class="portal-font" *matHeaderCellDef mat-sort-header>VALUE</mat-header-cell>
                    <mat-cell class="portal-font" *matCellDef="let item">{{item.value}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <div *ngIf="dataSourceForEvents.data.length === 0" class="portal-font mt20">No Records Found</div>
        </div>
        <div fxLayoutAlign="center" class="mt20" *ngIf="!isDataLoading">
            <mat-paginator class="portal-font" [pageSizeOptions]="[10, 15, 25, 100]"></mat-paginator>
        </div>
    </div>
</div>