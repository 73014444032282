        <app-header [isError]="false"></app-header>
        <div class="container mt30">
            <div class="flex-container" fxLayout="column" fxLayoutGap="20px">
                <div class="mt20">
                    <label class="portal-label">ACCESS TOKENS</label>
                    <button mat-raised-button (click)="openModal(template,true)"
                        class="create-button portal-font ophardt-button" [disabled]="isDataLoading">Create Token</button>
                </div>
                <div *ngIf="isDataLoading">
                    <mat-spinner class="portal-spinner"></mat-spinner>
                </div>
                <div fxLayout="row wrap" fxLayoutGap="16px grid" *ngIf="!isDataLoading">
                    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let item of userTokenObservable | async">
                        <mat-card class="mat-elevation-z9">
                            <mat-card-content>
                                <mat-card-subtitle>Expiry Date</mat-card-subtitle>
                                <p>{{item.expiredtimestamp}}</p>
                                <mat-card-subtitle>Name</mat-card-subtitle>
                                <p>{{item.name}}</p>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div fxLayoutAlign="center">
                    <mat-paginator class="portal-font" [pageSizeOptions]="[8, 16, 32, 100]"></mat-paginator>
                </div>
            </div>
        </div>
        <ng-template #template>
            <div id="modal-create-token" role="dialog" class="modal fade show tokenModal" aria-modal="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header portal-font">
                            <h2 *ngIf="isCreateMode" class="modal-title">Create Token</h2>
                            <h2 *ngIf="!isCreateMode" class="modal-title">Personal Access Token</h2>
                            <button type="button" (click)="modalRef.hide();resetTokenForm()" aria-hidden="true"
                                class="close">×</button>
                        </div>
                        <div class="modal-body tokenModalMargin portal-font">
                            <form [formGroup]="tokenForm">
                                <div *ngIf="isCreateMode" class="form-group row">
                                    <label for="name" class="col-md-4 col-form-label">Name</label>
                                    <input id="create-token-name" type="text" formControlName="name" class="form-control">
                                </div>
                                <div *ngIf="!isCreateMode" class="form-group row">
                                    <p>Here is your new personal access token. This is the only time it will be shown so don't
                                        lose it!You may now use this token to make API requests.</p>
                                    <textarea type="text" [readonly]="!isCreateMode" rows="10" formControlName="token"
                                        class="form-control"></textarea>
                                </div>
                                <div class="form-group col-md-6 padding-top modal-button">
                                    <button type="button" *ngIf="isCreateMode" [disabled]="loading" (click)="createToken()"
                                        class="btn ophardt-button button-text">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Create
                                    </button>
                                    <button type="button" class="btn cancel-btn" (click)="modalRef.hide();resetTokenForm()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>