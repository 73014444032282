import { Injectable } from '@angular/core';
import { Observable, Subject} from 'rxjs';
import {SettingsService} from '../services/settings.service';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import {  throwError } from 'rxjs';
import { map, tap,catchError, retryWhen, delay, retry } from 'rxjs/operators';

import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  token : string;
  private userLoggedIn = new Subject<boolean>();

  tokenObserver = new Subject<boolean>();
  public tokenSubscriber$ = this.tokenObserver.asObservable();

  constructor(private httpClient:HttpClient, private settingsService:SettingsService) {
    this.userLoggedIn.next(false);
  }
  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }
  
  getProfileName () {
    this.token = localStorage.getItem('portalToken');
    var decodedToken = jwt_decode(this.token);
    return decodedToken['preferred_username'];
  }

  getAccount () : Observable<any> {
    return this.httpClient.get(this.settingsService.getAccountUrl()).pipe(
      map((res) => {
      return res;
    }),
    delay(1000),
      retry(2),
      catchError (err =>{
        return throwError('Error Occurred.');
      })
    )
  }

  isAdmin() : boolean {
    this.token = localStorage.getItem('portalToken');
    var decodedToken = jwt_decode(this.token);
    if(decodedToken["groups"].includes("admin")){
      return true;
    }else{
      return false;
    }
  }

  emitIsAdmin() {
    this.tokenObserver.next(this.isAdmin());
  }

}
