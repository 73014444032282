import { Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerService } from '../services/customers.service';
import {Customer} from '../models/customer.model';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {deleteModal} from '../helpers/delete-modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { DevicesService } from '../services/devices.service';
import {SelectionModel} from '@angular/cdk/collections';
import { Subscription } from 'rxjs/internal/Subscription';


@Component({
  selector: 'app-customerdetails',
  templateUrl: './customerdetails.component.html',
  styleUrls: ['./customerdetails.component.sass']
})
export class CustomerdetailsComponent implements OnInit {
  customerId : string;
  isLoading :boolean = false;
  customer :Customer[] = [];
  isDataLoading :boolean =false;
  customerName: string;
  modalRef: BsModalRef;
  checkedCount:number = 0;
  selectedDevices: string[] =[];
  isTableLoading:boolean = false;
  filterField: string;
  loading: boolean = false;
  file: any;
  isFilePresent : boolean = false;
  fileInProgress : boolean = false;
  public dataSourceForCustomer = new MatTableDataSource<Customer>();
  public dataSourceForDevices = new MatTableDataSource<any>();
  public dataSourceForNewDevices = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);

  displayedColumnsOfCustomer: string[] = ['totalDevices','totalUsers','entityGroupId'];
  displayedColumnsOfDevices: string[] = ['name','type','Action'];
  displayedColumnsOfNewDevices: string[] = ['select','name','type'];
  
  

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private authService:AuthService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private devicesService:DevicesService) {
   }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => { 
      this.customerId = params.get('id'); 
  }); 
    this.getCustomerDetails();
    this.getDevicesForCustomer();
  }

  ngAfterViewInit() {
    this.dataSourceForDevices.paginator = this.paginator;
  }

  getCustomerDetails() {
    this.isLoading = true;
    this.customerService.getCustomerDetails(this.customerId).pipe(finalize(() => {
      this.isLoading = false;
    }))
    .subscribe(
      (data)=>{
        this.customer.push(data);
        this.dataSourceForCustomer = new MatTableDataSource(this.customer);
        this.customerName = data.name;
        this.isLoading = false;
      }
    )
  }

  getDevicesForCustomer() {
    this.isDataLoading = true;
    this.customerService.getDevicesForCustomer(this.customerId).pipe(finalize(() => {
      this.isDataLoading = false;
    }))
    .subscribe(
      (data)=>{
        this.dataSourceForDevices.data = data;
        this.isDataLoading = false;
      }
    )
  }

  deleteDeviceForCustomer(customerId: number, deviceId: string){
    this.customerService.deleteDeviceForCustomer(customerId,deviceId)
    .subscribe(
      (response) => {
        this.toastr.success('Deletion Successful!');
        this.getDevicesForCustomer();
        this.customer.splice(0);
        this.getCustomerDetails();
      }
    );
  }
  
   openDeleteModal(id: string, name: string): void {
    const dialogRef = this.dialog.open(deleteModal, {
      width: '450px',
      data: {name: name}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result) this.deleteDeviceForCustomer(Number(this.customerId),id);
    });
  }

  doDeviceFilter = (value: string) => {
    this.dataSourceForNewDevices.filter = value.trim().toLocaleLowerCase();
  }

  clearFilter(){
    this.dataSourceForNewDevices.filter = '';
    this.filterField = '';
  }

  getUnclaimedDevices(){
    this.isTableLoading = true;
    this.devicesService.getUnclaimedDevices().pipe(finalize(() => {
      this.isTableLoading = false;
    }))
    .subscribe(
      (data)=>{
        this.dataSourceForNewDevices.data = data;
        this.isTableLoading = false;
      }
    )
  }

  openDeviceModal(template: TemplateRef<any>) {
    this.getUnclaimedDevices();
    this.modalRef = this.modalService.show(template,{class: 'modal-lg modal-template', backdrop: 'static',
    keyboard: false});
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,{class: 'modal-lg', backdrop: 'static',
    keyboard: false});
  }
  
    isAllDevicesSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSourceForNewDevices.data.length;
      return numSelected === numRows;
    }
  
    masterCheckboxToggle() {
      this.checkedCount = 0;
      this.selectedDevices =[];
      if(this.isAllDevicesSelected()){
        this.selection.clear();
      }else{
          this.dataSourceForNewDevices.data.forEach(row =>{
            this.selection.select(row);
            this.selectedDevices.push(row.id.id);
            this.checkedCount++;
          });
        }
    }

    updateSelectedDeviceList(id: string, checked: boolean){
      if (checked) {
        this.selectedDevices.push(id) ;
        this.checkedCount++;
      } else{
        this.removeDeviceFromDeviceList(id);
        
      }
    }

    removeDeviceFromDeviceList(deviceId: string) {
      this.selectedDevices.forEach((value,index)=>{
          if(value==deviceId) {this.selectedDevices.splice(index,1);
          this.checkedCount--;}
      });
  }

  resetDeviceModal(){
    this.checkedCount =0;
    this.selectedDevices =[];
    this.selection.clear();
  }

  addDevicesToCustomer(){
    this.loading = true;
    this.customerService.addDevicesToCustomer(this.customerId,this.selectedDevices).pipe(finalize(() => {
      this.loading = false;
    }))
    .subscribe(
      (data)=>{
        this.onSuccess();
        this.resetDeviceModal();
      }
    )
  }

  uploadFile() {
    this.fileInProgress = true;
    if (this.file) {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        var result = fileReader.result.toString();
        if (result === "") {
          this.toastr.error("No device ids present in the file!")
          this.deleteFile();
        } else {
          var lines = result.split('\n');
          this.selectedDevices = [];
          lines.forEach(deviceId => {
            if (deviceId.length != 0) this.selectedDevices.push(deviceId.replace("\r", "").trim());
          });
          this.customerService.addDevicesToCustomer(this.customerId, this.selectedDevices)
            .pipe(finalize(() => {
              this.fileInProgress = false;
              this.deleteFile();
            })).subscribe(
              (data) => {
                this.onSuccess();
                this.deleteFile();
              },
              (error) => {
                this.deleteFile();
              }
            )
        }

      };
      fileReader.readAsText(this.file);
    }
  }

  onSuccess() {
    this.toastr.success('Addition Successful!');
    this.modalRef.hide();
    this.getDevicesForCustomer();
    this.customer.splice(0);
    this.getCustomerDetails();
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file) {
      this.isFilePresent = true;
    }
    event.target.value = '';
  }

  deleteFile() {
    if (this.file) {
      this.file = null;
      this.isFilePresent = false;
      this.fileInProgress = false;
    }
  }

}
