import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  portalUrl: string = environment.baseUrl + environment.basePortalHref;
  apiUrl: string = environment.baseUrl + environment.baseAPIHref;

  constructor() { }

  getLoginUrl(): string {
    return this.portalUrl + "/login";
  }

  getAccountUrl(): string {
    return this.portalUrl + "/account";
  }

  getDevicesUrl(): string {
    return this.apiUrl + "/devices";
  }

  getLogoutUrl(): string {
    return this.portalUrl + "/logout";
  }

  getEventsUrl(): string {
    return this.apiUrl + "/events";
  }

  getEventTypeUrl(): string {
    return this.apiUrl + "/eventtypes";
  }

  getRefreshUrl(): string {
    return this.portalUrl + "/refresh";
  }

  UserUrl(): string {
    return this.portalUrl + "/user";
  }

  CustomersUrl(): string {
    return this.portalUrl + "/customers";
  }

  UserTokenUrl(): string {
    return this.portalUrl + "/token";
  }
}
