import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerService } from '../services/customers.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import { first,finalize } from 'rxjs/operators';
import {Customer} from '../models/customer.model';
import { AuthService } from '../services/auth.service';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {deleteModal} from '../helpers/delete-modal';

@Component({
  selector: 'app-customerlist',
  templateUrl: './customerlist.component.html',
  styleUrls: ['./customerlist.component.sass']
})
export class CustomerlistComponent implements OnInit {

  displayedColumns: string[] = ['name','Action'];
  public dataSourceForCustomers = new MatTableDataSource<any>();
  customerForm: FormGroup;
  loading = false;
  submitted = false;
  modalRef: BsModalRef;
  isCreateMode: boolean = true;
  customer: Customer = new Customer();
  isLoading :boolean = false;
  editedCustomerId : number;
  selectedIndex : number;
  isSpinnerOn = false;
  filterField: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort, {static: false}) set content(sort: MatSort) {
    this.dataSourceForCustomers.sort = sort;
  }
  constructor(private customerService: CustomerService,private formBuilder: FormBuilder,public modalService: BsModalService,
    private toastr: ToastrService,private authService:AuthService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.dataSourceForCustomers.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
    this.getCustomers();
    this.initializeCustomerForm();
  }

  ngAfterViewInit() {
    this.dataSourceForCustomers.paginator = this.paginator;
  }

  initializeCustomerForm() {
    this.customerForm = this.formBuilder.group({
      name: ['', Validators.required],
      ohmsCustomer: [false],
      owmsCustomer: [false]
    });
  }

  get formFields() {
    return this.customerForm.controls;
  }

  resetCustomerForm() {
    this.initializeCustomerForm();
    this.submitted = false;
  }

  getCustomers() {
    this.isLoading = true;
    this.customerService.getCustomers()
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe(
      data =>{
      this.dataSourceForCustomers.data = data;
     })
  }

  onSubmit() {
    this.submitted = true;
    if(!this.customerForm.touched){
      this.modalRef.hide();
      return;
    } 
    if (this.customerForm.invalid) {
      this.toastr.error('Error: Form is Invalid!'); 
      return;
    }
    this.loading = true;
    if (this.isCreateMode) {
    this.createCustomer();
    } else {
        this.updateCustomer();
    }
  }

  openModal(template: TemplateRef<any>, createMode : boolean) {
    this.isCreateMode = createMode;
    this.modalRef = this.modalService.show(template, {class: 'modal-md modal-template modal-checkbox'});
  }

  getCustomerById(id: string, index: number,template: TemplateRef<any>,createMode : boolean){
    this.selectedIndex = index;
    this.isSpinnerOn = true;
    this.customerService.getCustomerDetails(id)
    .pipe(
      finalize(() => {
        this.isSpinnerOn = false;
      })
    )
    .subscribe(
      (response) => {
        this.editedCustomerId = response.id;
        this.customerForm.patchValue(response);
        this.openModal(template,createMode);
      }
    );
  }

  createCustomer(){
    this.customerService.createCustomer(this.customerForm.value)
    .pipe(
      finalize(() => {
        this.loading = false;
      })
    )
    .subscribe(
      (data) => {
        this.toastr.success('Creation Successful!');
        this.modalRef.hide();
        this.resetCustomerForm();
        this.getCustomers();
      }
    );
  }

  updateCustomer () { 
    this.customer.name = this.formFields.name.value;
    this.customer.id = this.editedCustomerId;
    this.customer.ohmsCustomer = this.formFields.ohmsCustomer.value;
    this.customer.owmsCustomer = this.formFields.owmsCustomer.value;
    this.customerService.updateCustomer(this.customer)
    .pipe(
      finalize(() => {
        this.loading = false;
      })
    )
    .subscribe(
      (data) => {
        this.toastr.success('Updation Successful!');
        this.modalRef.hide();
       this.resetCustomerForm();
        this.getCustomers();
      }
    );
  }

  public doFilter = (value: string) => {
    this.dataSourceForCustomers.filter = value.trim().toLocaleLowerCase();
  }

  clearFilter(){
    this.dataSourceForCustomers.filter = '';
    this.filterField = '';
 }

 deleteCustomer(id: string){
  this.customerService.deleteCustomer(id)
  .subscribe(
    (response) => {
      this.toastr.success('Deletion Successful!');
      this.getCustomers();
    }
  );
}

 openDeleteModal(id: string, name: string): void {
  const dialogRef = this.dialog.open(deleteModal, {
    width: '450px',
    data: {name: name}
  });

  dialogRef.afterClosed().subscribe(result => {
    if(result) this.deleteCustomer(id);
  });
}

}
