import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import {SettingsService} from '../services/settings.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private settingsService:SettingsService , private httpClient:HttpClient ) { }

  getCustomers () :Observable<any> {
    return this.httpClient.get(this.settingsService.CustomersUrl());
  }

  getCustomerDetails (customerId:string) : Observable<any>{
    return this.httpClient.get(this.settingsService.CustomersUrl()+'/'+customerId);
  }

  createCustomer (body:any) :Observable<any> {
    return this.httpClient.post(this.settingsService.CustomersUrl(),body,{responseType: 'json'});
  }

  updateCustomer (body:any) :Observable<any> {
    return this.httpClient.put(this.settingsService.CustomersUrl(),body,{responseType: 'json'});
  }

  deleteCustomer(customerId:string) : Observable<any>{
    return this.httpClient.delete(this.settingsService.CustomersUrl()+'/'+customerId);
  }

  getDevicesForCustomer(customerId:string) : Observable<any>{
    return this.httpClient.get(this.settingsService.CustomersUrl()+'/'+customerId+'/devices');
  }

  deleteDeviceForCustomer(customerId:number,deviceId:string) : Observable<any>{
    return this.httpClient.delete(this.settingsService.CustomersUrl()+'/'+customerId+'/devices/'+deviceId);
  }

  addDevicesToCustomer(customerId:string,body:any) :Observable<any> {
    return this.httpClient.post(this.settingsService.CustomersUrl()+'/'+customerId+'/devices',body,{responseType: 'json'});
  }
}
