import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { finalize } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { UsertokenService } from '../services/usertoken.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-usertokens',
  templateUrl: './usertokens.component.html',
  styleUrls: ['./usertokens.component.sass']
})
export class UsertokensComponent implements OnInit {
  displayedColumns: string[] = ['expiredtimestamp', 'name'];
  public dataSourceForUserTokens = new MatTableDataSource<any>();
  isDataLoading: boolean = false;
  isCreateMode: boolean = true;
  tokenForm: FormGroup;
  modalRef: BsModalRef;
  loading = false;
  userTokenObservable: Observable<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private usertokenService: UsertokenService,
    public modalService: BsModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.userTokenObservable = this.dataSourceForUserTokens.connect();
    this.initializeUserTokenForm();
    this.getUserTokens();
  }

  initializeUserTokenForm() {
    this.tokenForm = this.formBuilder.group({
      name: [''],
      token: [''],
    });
  }

  ngAfterViewInit() {
    this.dataSourceForUserTokens.paginator = this.paginator;
  }

  resetTokenForm() {
    this.tokenForm.reset();
    this.isCreateMode = false;
    this.formFields.name.setValue('');
  }

  get formFields() {
    return this.tokenForm.controls;
  }

  openModal(template: TemplateRef<any>, createMode: boolean) {
    this.isCreateMode = createMode;
    this.modalRef = this.modalService.show(template, {class: 'modal-md modal-template modal-checkbox'});
  }

  createToken() {
    this.loading = true;
    this.usertokenService
      .createUserToken(this.formFields.name.value)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((data) => {
        this.toastr.success('Creation Successful!');
        this.resetTokenForm();
        this.getUserTokens();
        this.formFields.token.setValue(data);
      });
  }

  getUserTokens() {
    this.isDataLoading = true;
    this.usertokenService.getUserTokens()
      .pipe(
        finalize(() => this.isDataLoading = false)
      )
      .subscribe((data) => {
        this.dataSourceForUserTokens.data = data;
        this.dataSourceForUserTokens.paginator = this.paginator;
      }
      );
  }

}
