import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { ProfileService } from '../services/profile.service';
import { AuthService } from '../services/auth.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordMatch } from '../helpers/password-match.validator';
import { UserService } from '../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl } from '@angular/forms'
import { saveAs } from 'file-saver/dist/FileSaver';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.sass']
})
export class SideNavBarComponent implements OnInit {
  @Input() isAdmin: boolean;
  userName: string;
  modalRef: BsModalRef;
  resetPasswordForm: FormGroup;
  submitted: boolean = false;
  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;

  constructor(    private profileService: ProfileService, private formBuilder: FormBuilder,
    private authService: AuthService, public modalService: BsModalService, private userService: UserService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.userName = this.profileService.getProfileName();
    this.initializeResetPasswordForm();
  }

  logout() {
    this.authService.logout();
  }

  initializeResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmation: ['', Validators.required] 
    },{
      validator: PasswordMatch('newPassword', 'confirmation')
    }
    );
  }

  openResetPasswordModal(template: TemplateRef<any>) {
    this.initializeResetPasswordForm();
    this.submitted = false;
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }

  reinitializePasswordForm() {
    this.submitted = false;
    this.resetPasswordForm.reset();
    this.resetPasswordForm.clearValidators();
    Object.keys(this.resetPasswordForm.controls).forEach(key => {
      this.resetPasswordForm.get(key).setErrors(null) ;
    });
    this.showConfirmPassword = false;
    this.showOldPassword = false;
    this.showNewPassword = false;
  }

  onSubmit() {
    this.submitted = true;
    if (this.resetPasswordForm.invalid || !this.resetPasswordForm.touched) {
      return;
    }
    this.resetPassword();
  }

  get formFields() {
    return this.resetPasswordForm.controls;
  }

  password(labelName : string) {
    if(labelName === 'newPassword') this.showNewPassword = !this.showNewPassword;
    if(labelName === 'confirmation') this.showConfirmPassword = !this.showConfirmPassword;
    if(labelName === 'currentPassword') this.showOldPassword = !this.showOldPassword;
  }

  resetPassword(){
    this.userService.resetUserPassword(this.resetPasswordForm.value).subscribe(
      (data) => {
        this.toastr.success('Password Updated. Please Login Again!');
        this.modalRef.hide();
        this.reinitializePasswordForm();
        this.authService.logout();
      }
    )
  }

  download(){
    this.userService.downloadSwagger().subscribe(
      (data) => {
      var file = new File([data], "swagger.yaml", { type: 'text/plain;charset=utf-8' });
      saveAs(file);
    } );             
  }

}
