import { Component, OnInit } from '@angular/core';

import { ProfileService} from '../services/profile.service';
import { SettingsService} from '../services/settings.service';
import { Router } from '@angular/router';

import{AuthService} from '../services/auth.service';

@Component({
  selector: 'app-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.sass']
})
export class ErrorpageComponent implements OnInit {

  constructor(private profileService:ProfileService,private router: Router, private settingsService:SettingsService, private authService:AuthService) { }

  ngOnInit(): void {}
  logout() {
   this.authService.logout();

}
}
