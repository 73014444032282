import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import {SettingsService} from '../services/settings.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private settingsService:SettingsService , private httpClient:HttpClient ) { }

  getUsersAndRoles () :Observable<any> {
    return this.httpClient.get(this.settingsService.UserUrl());
  }

  createUser (body:any) :Observable<any> {
    return this.httpClient.post(this.settingsService.UserUrl(),body,{responseType: 'json'});
  }

  getUserByUserName (userName:string) :Observable<any> {
    return this.httpClient.get(this.settingsService.UserUrl()+'/'+userName);
  }

  updateUser  (body:any) :Observable<any> {
    return this.httpClient.put(this.settingsService.UserUrl(),body,{responseType: 'json'});
  }

  deleteUser (userName:string) :Observable<any> {
    return this.httpClient.delete(this.settingsService.UserUrl()+'/'+userName);
  } 
  
  resetUserPassword (body:any) :Observable<any> {
    return this.httpClient.post(this.settingsService.portalUrl+'/auth/reset',body,{responseType: 'json'});
  }

  downloadSwagger () :Observable<any> {
    return this.httpClient.get(this.settingsService.portalUrl+'/swagger',{responseType: 'arraybuffer'});
  } 
  
}
