<mat-toolbar class="app-header">
    <mat-toolbar-row *ngIf="!isError">
        <div fxFlex></div>
        <div fxFlex fxLayoutAlign="center center">
           <a [routerLink] ="['/home']"><img src="/assets/ophardt-hygiene.png" class="logo-img" /></a> 
        </div>
        <div fxFlex fxLayoutAlign="end end">
            <span class="portal-header-text username-text">{{userName}}</span>
        </div>
    </mat-toolbar-row>
  </mat-toolbar>
  