<div fxLayout="column" fxFlex="10" fxLayoutAlign="space-evenly center start"><a mat-button [routerLink] ="['/home']"><img src="/assets/ophardt-icon.png" class="logo-icon"/></a> </div>
<div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center start" fxFlex="60">
    <div>
        <a mat-button class="portal-header" [routerLink] ="['/home']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
            <mat-icon class="portal-header-icon">home</mat-icon>
            <p>Home</p>
        </a>
    </div>
    <div>
        <a mat-button *ngIf="isAdmin" class="portal-header" [routerLink] ="['/userList']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
            <mat-icon class="portal-header-icon">person</mat-icon>
            <p>Users</p>
        </a>
    </div>
    <div>
        <a mat-button *ngIf="isAdmin" class="portal-header" [routerLink] ="['/customerList']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
            <mat-icon class="portal-header-icon">face</mat-icon>
            <p>Customers</p>
        </a>
    </div>
</div>
<div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px" fxFlex="30">
    <div>
        <a mat-button [matMenuTriggerFor]="menu" class="portal-header">
            <mat-icon class="portal-header-icon">more_horiz</mat-icon>
            <p>More</p>
        </a>
    </div>
        <mat-menu #menu="matMenu" class="more-menu">
            <button mat-menu-item [routerLink]="['/userTokens']" routerLinkActive="active-link">
                <mat-icon class="portal-header-icon">vpn_key</mat-icon>
                <span class="portal-header-text">Access Tokens</span>
            </button>
            <button mat-menu-item (click)="openResetPasswordModal(template)" routerLinkActive="active-link">
                <mat-icon class="portal-header-icon">lock</mat-icon>
                <span class="portal-header-text">Reset Password</span>
            </button>
            <button mat-menu-item (click)="download()" routerLinkActive="active-link">
                <mat-icon>cloud_download</mat-icon>
                <span class="portal-header-text">Get API Swagger</span>
            </button>
        </mat-menu>
    <div>
        <a mat-button (click)="logout()"  class="portal-header">
            <mat-icon class="portal-header-icon">logout</mat-icon>
            <p>Logout</p>
        </a>
    </div>
</div> 

<ng-template #template>
    <div class="modal-header portal-font">
        <h2 class="modal-title pull-left">Reset Password</h2> 
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide();reinitializePasswordForm();">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body portal-font">
        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <div class="form-group password-label">
                    <label for="currentPassword">Current Password</label>
                </div>
                <div class="form-row">
                    <div class="form-group col-11">
                        <input [type]="showOldPassword ? 'text' : 'password'" formControlName="currentPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && formFields.currentPassword.errors }" />
                        <div *ngIf="submitted && formFields.currentPassword.errors" class="invalid-feedback">
                        <div *ngIf="formFields.currentPassword.errors.required">Current password is required</div>
                        </div>
                    </div>
                    <div class="form-group col-1 password-viewer">
                        <mat-icon matSuffix (click)="password('currentPassword')">{{showOldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="form-group password-label">
                    <label for="newPassword">New Password</label>
                </div>
                <div class="form-row">
                    <div class="form-group col-11">
                        <input [type]="showNewPassword ? 'text' : 'password'" formControlName="newPassword" class="form-control" minlength="10" [ngClass]="{ 'is-invalid': submitted && formFields.newPassword.errors }" />
                        <div *ngIf="submitted && formFields.newPassword.errors" class="invalid-feedback">
                            <div *ngIf="formFields.newPassword.errors.required">New password is required</div>
                            <div *ngIf="formFields.newPassword.errors.minlength">Password must be at least 10 characters</div>
                        </div>
                    </div>
                    <div class="form-group col-1 password-viewer">
                        <mat-icon matSuffix (click)="password('newPassword')">{{showNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="form-group password-label">
                    <label for="confirmation">Confirm Password</label>
                </div>
                <div class="form-row">
                    <div class="form-group col-11">
                        <input [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirmation" class="form-control" minlength="10" [ngClass]="{ 'is-invalid': submitted && formFields.confirmation.errors }" />
                        <div *ngIf="submitted && formFields.confirmation.errors" class="invalid-feedback">
                        <div *ngIf="formFields.confirmation.errors.required">Confirm password is required</div>
                        <div *ngIf="formFields.confirmation.errors.minlength">Password must be at least 10 characters</div>
                        <div *ngIf="formFields.confirmation.errors.mustMatch">Passwords must match</div>
                        </div>
                    </div>
                    <div class="form-group col-1 password-viewer">
                        <mat-icon matSuffix (click)="password('confirmation')">{{showConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </div>
                </div>
            </div>
                    <div class="form-group">
                        <button class="btn ophardt-button button-text">
                        Submit
                        </button>
                        <button class="btn cancel-btn" (click) ="modalRef.hide();reinitializePasswordForm()">
                        Cancel
                         </button>
            </div>
        </form>
    </div>
</ng-template>

