export class User {

    firstName: string;
    lastName : string;
    userName : string;
    customerName : string;
    enabled : boolean;
    admin : boolean;
    
    constructor() {
    }
}