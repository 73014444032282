import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {MatSort, Sort} from '@angular/material/sort';

import { DevicesService } from '../services/devices.service';
import { ActivatedRoute } from '@angular/router';
import { Device } from '../models/device.model';

import { ProfileService} from '../services/profile.service';
import {AuthService} from '../services/auth.service';
import {SettingsService} from '../services/settings.service';
import { UsertokenService } from '../services/usertoken.service';

import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.sass'],
})
export class HomepageComponent implements OnInit {
  token: string;
  refreshToken : string;
  deviceList:  Device[];
  displayedColumns: string[] = ['name', 'type'];
  showTable :boolean = false;
  isLoading :boolean = false;
  dataSourceForDevices = new MatTableDataSource<Device>();
  filterField: string;


   public timeOutModal: BsModalRef;
   @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private devicesService: DevicesService,
    private activatedRoute: ActivatedRoute,
    private profileService:ProfileService,
    private router: Router,
    private authService: AuthService,
    private idle: Idle,
    private modalService: BsModalService,
    private settingsService:SettingsService,
    private usertokenService:UsertokenService
  ) {
        // sets an idle timeout of 29 minutes, after which the timeout modal is shown for a minute.
        idle.setIdle(1740);
        // sets a timeout period of 60 seconds. after 30 minutes of inactivity, the user will be logged out.
        idle.setTimeout(60);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document.
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    
        idle.onIdleEnd.subscribe(() => { 
          this.reset();
        });
        
        idle.onTimeout.subscribe(() => {
          this.timeOutModal.hide();
          this.authService.logout();
        });
        
        idle.onIdleStart.subscribe(() => {
            this.timeOutModal = this.modalService.show(ChildModalComponent);
        });
        
        this.profileService.getUserLoggedIn().subscribe(userLoggedIn => {
          if (userLoggedIn) {
            idle.watch()
          } else {
            idle.stop();
          }
        })
  }

  ngOnInit(): void {
    this.dataSourceForDevices.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
    this.showTable = false;
    if(localStorage.getItem('isRefreshTimerOn') !== 'true'){
        this.activatedRoute.queryParams.subscribe((params) => {
          this.token = params['bt'];
          localStorage.setItem('portalToken', this.token);
          this.profileService.emitIsAdmin();
          this.refreshToken = params['rt'];
          localStorage.setItem('refreshToken', this.refreshToken);
        })
        if(this.token === undefined || this.token === null){
          localStorage.clear();
          window.location.href = this.settingsService.getLoginUrl();
        }else{
          this.profileService.setUserLoggedIn(true);
          localStorage.setItem('isRefreshTimerOn', 'true');
          this.authService.startRefreshTokenTimer();
        }
    }
    this.profileService.getAccount().subscribe(
      (res) =>{
        this.isLoading = true;
        this.usertokenService.getSessionToken().subscribe(token =>{
          localStorage.setItem('apiToken',token);
          this.getDevices();
        })   
      },
      (error) =>{
        if(this.token != undefined || this.token != null){
        this.router.navigate(['/error']);}
      }
    )
    window.history.replaceState({}, document.title, window.location.pathname);
  }

  ngAfterViewInit() {
    this.dataSourceForDevices.paginator = this.paginator;
  }

  sortData(sort: Sort): void {
    if (sort.direction === '') {
      this.dataSourceForDevices.data = this.deviceList;
      return;
    }

    const sortedDeviceList = this.deviceList.slice();
    sortedDeviceList.sort(
      (device1: Device, device2: Device) => sort.direction === 'asc'
        ? _sortAlphanumeric(device1[sort.active], device2[sort.active])
        : _sortAlphanumeric(device2[sort.active], device1[sort.active])
    );
    this.dataSourceForDevices.data = sortedDeviceList;
  }

  getDevices() {
    this.showTable = false;
    this.isLoading = true;
    this.devicesService.getDevices()
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
   )
    .subscribe((data) => {
        this.showTable = true;
        this.deviceList = data;
        this.dataSourceForDevices.data = this.deviceList;
      },
      (error) => {
        if(error === 401){this.authService.redirectToLoginPage();}
      });
  }

  reset() {
    this.idle.watch();
  }

  public doFilter = (value: string) => {
    this.dataSourceForDevices.filter = value.trim().toLocaleLowerCase();
  }

  clearFilter(){
    this.dataSourceForDevices.filter = '';
    this.filterField = '';
 }
 
}

/* This is the Timeout Modal Component */
@Component({
  selector: 'child-modal',
  template: `
  <div class="modal-header">
    <h4 id="dialog-child-name" class="modal-title pull-left">You Have Been Idle!</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mrgbtm">
      <div class="col-md-6">
        <button type="button" (click)="logout()" class="btn btn-danger">Logout</button>
      </div>
    </div>
  </div>
  `
})
export class ChildModalComponent {
  constructor(public idleModal: BsModalRef, private authService: AuthService,private profileService:ProfileService) {}
  
  
  hideChildModal(): void {
    this.idleModal.hide();
  }

  logout() {
    this.idleModal.hide();
    this.profileService.setUserLoggedIn(false);
    this.authService.logout();
  }
}

function _sortAlphanumeric(target: string, source: string): number {
  return target.localeCompare(source, 'en', { numeric: true });
}
