import { Component,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
    selector: 'delete-modal',
    templateUrl: 'delete-modal.html'
  })
  export class deleteModal {
  
    constructor(public dialogRef: MatDialogRef<deleteModal>,@Inject(MAT_DIALOG_DATA) public data: {name: string}) {}
  
    closeModal(): void {
      this.dialogRef.close();
    }
  
  }