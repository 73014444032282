import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import {SettingsService} from '../services/settings.service';
import { HttpClient } from '@angular/common/http';
import {  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  constructor(private settingsService:SettingsService , private httpClient:HttpClient ) { }

  getDevices () :Observable<any> {
    return this.httpClient.get(this.settingsService.getDevicesUrl());
  }
   
  getUnclaimedDevices() : Observable<any>{
    return this.httpClient.get(this.settingsService.portalUrl+'/devices/unclaimed');
  }
}
