import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../services/settings.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsertokenService {

  constructor(private settingsService: SettingsService, private httpClient: HttpClient) { }

  getUserTokens(): Observable<any> {
    return this.httpClient.get(this.settingsService.UserTokenUrl());
  }

  createUserToken(body: any): Observable<any> {
    return this.httpClient
      .post(this.settingsService.UserTokenUrl(), body, { responseType: 'text' });
  }

  getSessionToken(): Observable<any> {
    return this.httpClient.put(this.settingsService.UserTokenUrl(),null,{ responseType: 'text' });
  }
}
